import React, {useState, useRef, useEffect} from "react";
import Layout from "../comps/Layout";
import Hero from "../comps/Hero";
import Section from "../comps/Section";
import Stack from "../comps/Stack";
import Stat from "../comps/Stat";
import Input from "../comps/Input";
import Puff from "../comps/Puff";
import Button from "../comps/Button";
import ContactItem from "../comps/ContactItem";
import Container from "../comps/Container";
import { Link } from "gatsby";


const IndexPage = () => {
  const [context, setContext] = useState(0); 
  const [scrolled, setScrolled] = useState(0);
  const [showButtons, setShowButtons] = useState(false);
  const [didPost, setDidPost] = useState(false);
  const [email, setEmail] = useState(false);
  const [clicked, setClicked] = useState(false);
  const myRef = useRef(null);
  const myRefCTA = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  const goCTA = () => myRefCTA.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const contextButtonClicked = (c) => {
    setClicked(true);
    executeScroll();
    setContext(c)
  }

  const handleScroll = () => {
    setScrolled(window.scrollY);
    setShowButtons(window.scrollY > (myRef?.current?.offsetTop + 60));
  }
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Layout context={context} contextButtonClicked={contextButtonClicked} showButtons={showButtons} goCTA={goCTA} clicked={clicked}>
      <title>Villkor - Markpartner</title>
      <Section>
        <Container className="plain" narrow>
          <h1>Our Company Privacy Policy</h1>
          <p>Markpartner is a brand name of services provided by Markpartner Sverige AB. This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.</p>
          <p>Topics:</p>
          <ul>
              <li>What data do we collect?</li>
              <li>How do we collect your data?</li>
              <li>How will we use your data?</li>
              <li>How do we store your data?</li>
              <li>Marketing</li>
              <li>What are your data protection rights?</li>
              <li>Changes to our privacy policy</li>
              <li>How to contact us</li>
              <li>How to contact the appropriate authorities</li>
          </ul>
          <h2>What data do we collect?</h2>
          <p>Markpartner Sverige AB collects the following data:</p>
          <ul>
              <li>Personal identification information (Name, phone number).</li>
          </ul>
          <h2>How do we collect your data?</h2>
          <p>You directly provide Markpartner Sverige AB with the data we collect. We collect data and process data when you:</p>
          <ul>
              <li>Voluntarily complete a contact form on our website <a href="https://www.markpartner.se">www.markpartner.se</a>.</li>
          </ul>
          <h2>How will we use your data?</h2>
          <p>Markpartner Sverige AB collects your data so that we can:</p>
          <ul>
              <li>Contact you via phone with services we think you might be interested in.</li>
          </ul>
          <h2>How do we store your data?</h2>
          <p>Markpartner Sverige AB securely stores your data at the servers of our website provider.</p>
          <p>Markpartner Sverige AB will keep your personal data for 1 year. Once this time period has expired, we will delete your data by deletion on our website provider’s servers.</p>
          <h2>Marketing</h2>
          <p>Markpartner Sverige AB would like to contact you with information about products and services of ours that we think you might be interested in.</p>
          <p>If you have agreed to receive marketing, you may always opt out at a later date.</p>
          <p>You have the right at any time to stop Markpartner Sverige AB from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please send an email specifying this demand to <a href="mailto:kontakt@markpartner.se">kontakt@markpartner.se</a>.</p>
          <h2>What are your data protection rights?</h2>
          <p>Markpartner Sverige AB would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
          <p><b>The right to access</b> – You have the right to request Markpartner Sverige AB for copies of your personal data. We may charge you a small fee for this service.</p>
          <p><b>The right to rectification</b> – You have the right to request that Markpartner Sverige AB correct any information you believe is inaccurate. You also have the right to request Markpartner Sverige AB to complete information you believe is incomplete.</p>
          <p><b>The right to erasure</b> – You have the right to request that Markpartner Sverige AB erase your personal data, under certain conditions.</p>
          <p><b>The right to restrict processing</b> – You have the right to request that Markpartner Sverige AB restrict the processing of your personal data, under certain conditions.</p>
          <p><b>The right to object to processing</b> – You have the right to object to Markpartner Sverige AB’s processing of your personal data, under certain conditions.</p>
          <p><b>The right to data portability</b> – You have the right to request that Markpartner Sverige AB transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
          <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: <a href="mailto:kontakt@markpartner.se">kontakt@markpartner.se</a>.</p>
          <p>Call us at: <a href="tel:+46104300380">+46 10 – 430 03 80</a>.</p>
          <p>Or write to us at: Markpartner Sverige AB, Birger Jarlsgatan 2, 114 34 Stockholm.</p>
          <h2>Changes to our privacy policy</h2>
          <p>Our Company keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 10 May 2024.</p>
          <h2>How to contact us</h2>
          <p>If you have any questions about Markpartner Sverige AB’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us. Email us at: <a href="mailto:kontakt@markpartner.se">kontakt@markpartner.se</a>.</p>
          <p>Call us: <a href="tel:+46104300380">+46 10 430 03 80</a>.</p>
          <p>Or write to us at: Markpartner Sverige AB, Birger Jarlsgatan 2, 114 34 Stockholm.</p>
          <h2>How to contact the appropriate authority</h2>
          <p>Should you wish to report a complaint or if you feel that Markpartner Sverige AB has not addressed your concern in a satisfactory manner, you may contact Integritetsskyddsmyndigheten.</p>
          <p>Email: <a href="mailto:imy@imy.se">imy@imy.se</a></p>
          <p>Address: Integritetsskyddsmyndigheten, Box 8114, 104 20 Stockholm.</p>

        </Container>
      </Section>
    </Layout>
  );
};

export default IndexPage;
